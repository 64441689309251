<template>
    <div class="already_select_store">
        <div class="already_left">
            <img src="@/assets/ze-shop-o_icon.png" alt="">
            <span>{{ $store.state.shopItem.dianName || $store.state.shopItem.storeName}}</span>
            <div class="spcLine" />
        </div>
        <div class="already_right">
            <!-- <div class="btn1 m28" @click="goShop"><img src="@/assets/shop_cart.png" />绿优海购</div> -->
            <div class="btn2" @click="goShop"><img src="@/assets/toStore.png" />到店甄选</div>
        </div>
    </div>
</template>
<script>
import { reactive, ref, onMounted } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'


export default {
    name: 'index',
    components: {
    },
    setup() {
        const store = useStore();
        const router = useRouter()

        onMounted(() => { })
        const goShop = () => {
            if(Object.keys(store.state.shopItem || {}).length > 0){
                let routeUrl = router.resolve({
                    path: '/store/index',
                    query: {
                        vid: store.state.shopItem.storeId,
                    }
                })
                window.open(routeUrl.href, '_blank');
            } else {
                store.commit('setCenterMoal', 1);
            }
            
        }
        return {
            goShop,
        }
    }
}
</script>
<style lang="scss" scoped>
.already_select_store {
    width: 1210px;
    height: 85px;
    background-color: #fff;
    margin: 20px auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 29px 0 20px;

    .already_left {
        display: flex;
        align-items: center;
        position: relative;
        .spcLine{
            position: absolute;
            left: 4px;
            bottom: -8px;
            width: 176px;
            height: 12px;
            background: linear-gradient(90deg, rgba(23,111,87,0.3) 0%, rgba(23,111,87,0) 100%);
        }
        img {
            width: 27px;
            height: 24px;
        }

        span {
            font-size: 24px;
            font-weight: bold;
            font-family: PingFang SC;
            color: #333333;
            margin-left: 8px;
        }
    }

    .already_right {
        height: 100%;
        display: flex;
        align-items: center;
        img{
            width: 26px;
            height: 26px;
            margin-right: 10px;
            margin-bottom: 3px;
        }
        .btn1 {
            color: #fff;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 160px;
            height: 44px;
            background: #176F57;
            border-radius: 22px;
        }
        .btn2 {
            color: #176F57;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            width: 161px;
            height: 45px;
            background: rgba(23,111,87,0.1);
            border: 1px solid #1F745D;
            border-radius: 22px;
        }

        .m28 {
            margin-right: 28px;
        }
    }

}</style>
  